/** Dependencies */
import React, { FC } from 'react'
import cx from 'classnames'

/** Images */
import closeIcon from '../../assets/svg/closeIcon.svg'
import modalBorder from '../../assets/png/modal-border.png'
import modalBackground from '../../assets/png/modal-background.png'
import modalSeparator from '../../assets/svg/HelloWandererPopup_SeparatorDecor_v001_001.svg'

/** Styles */
import styles from './Modal.module.scss'

type ModalProps = {
  visibility: boolean;
  onCancel: React.MouseEventHandler;
  zIndex: number;
  children: React.ReactNode;
}

const Modal: FC<ModalProps> = ({ visibility, onCancel, zIndex, children }) => {
  return (
    <div
      className={cx(styles.modalContainer, { [styles.visible]: visibility })}
      style={{ zIndex }}
    >
      <div className={styles.modal}>
        <img className={styles.closeIcon} onClick={onCancel} src={closeIcon} alt="close"/>
        <img className={styles.modalBorder} src={modalBorder} alt={'modal border'} />
        <img className={styles.modalBackground} src={modalBackground} alt={'modal background'} />
        <div className={styles.typeWriter}>
          Greetings wanderer, our systems indicate your details have not been detected.
        </div>
        <img className={styles.separator} src={modalSeparator} alt={'separator'} />
        <div className={styles.question}>Would you like to join?</div>
        {children}
      </div>
    </div>
  )
}

export default Modal