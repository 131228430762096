/** Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/** Images */
import ButtonSVG from '../../assets/svg/button-right-no-border.svg';
import ButtonRightBorder from '../../assets/svg/button-right-decoration-border.svg';

/** Styles */
import styles from './ButtonRight.module.scss';

const ButtonRight = ({ url, text, scaled, handleButtonClick }) => {
  return (
    <a
      href={url}
      target={'_blank'}
      rel={'noreferrer'}
      className={cx(styles.buttonRight, { [styles.scaled]: scaled })}
    >
      <img className={styles.buttonRight} src={ButtonSVG} alt='button' onClick={handleButtonClick} />
      <img
        className={styles.buttonDecorationBorderRight}
        src={ButtonRightBorder}
        alt='button bottom right border'
      />
      <span className={styles.buttonRightText}>{text.split(' ')[0]}</span>
      <span className={styles.secondWordRightText}>{text.split(' ')[1]}</span>
    </a>
  );
};

ButtonRight.propTypes = {
  url: PropTypes.string,
  scaled: PropTypes.bool,
  text: PropTypes.string,
};

export default ButtonRight;
