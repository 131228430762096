import { ImmutableXClient, Link, ProviderPreference } from '@imtbl/imx-sdk';

export const wallet = () => {
  let linkAddress = process.env.REACT_APP_IMX_LINK_ADDRESS;
  let apiAddress = process.env.REACT_APP_IMX_API_ADDRESS;
  const connectIMX = async () => {
    const linkIframeOptions = {
      className: 'my-link-iframe',
    };

    // Link SDK
    const link = new Link(linkAddress, linkIframeOptions);

    // IMX Client
    const client = await ImmutableXClient.build({ publicApiUrl: apiAddress });
    return { link, client };
  };

  const connectWallet = async () => {
    let imx = await connectIMX();
    try {
      //wallet connection
      const { address, starkPublicKey } = await imx.link.setup({
        providerPreference: ProviderPreference.METAMASK,
      });

      console.log('address, starkPublicKey', address, starkPublicKey);

      //wallet authentication
      const authRes = await imx.link.sign({
        message: 'PQ authentication message',
        description:
          'Sign this message if you would like to auth with PlanetQuest',
      });
      const uniqueIdUser = authRes.result;

      console.log('AUTHENTICATION_ID', authRes.result);

      return { address, starkPublicKey, uniqueIdUser };
    } catch (err) {
      console.error('Window closed before wallet connection established.');
    }
  };

  return {
    wallet,
    connectWallet,
    hasWallet: !!wallet.address,
  };
};
