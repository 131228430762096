/** Dependencies */
import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

/** Images */
import headerBorder from './assets/svg/top-border-2.svg';
import headerBackground from './assets/svg/header-background.svg';
// import normalDifficulty from './assets/svg/DifficultyButtons_Left_v001_001.svg';
// import hardDifficulty from './assets/svg/DifficultyButtons_Centre_v001_001.svg';
// import impossibleDifficulty from './assets/svg/DifficultyButtons_Right_v001_001.svg';
import logo from './assets/png/logo.png';

/** Wallet */
import { wallet } from './wallet';

/** Components */
import Modal from './components/Modal/Modal';

/** Utils */
import {
  isMobile,
  getContinentByIP,
  getContinentByTimezone,
} from './utils/functions';

/** Styles */
import styles from './App.module.scss';
import './App.css';
import ButtonGroup from './components/ButtonGroup/ButtonGroup';

function App() {
  const { connectWallet } = wallet();

  const [walletId, setWalletId] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [browserId, setBrowserId] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [playerName, setPlayerName] = useState('');
  const [shown, setShown] = useState(false);
  const [modalZIndex, setModalZIndex] = useState(-1);
  const [paramsURL, setParamsURL] = useState('');

  const [continent, setContinent] = useState('');

  const setWalletToLocalStorage = ({
    address,
    starkPublicKey,
    uniqueIdUser,
  }) => {
    localStorage.setItem('address', JSON.stringify(address));
    localStorage.setItem('starkPublicKey', JSON.stringify(starkPublicKey));
    localStorage.setItem('uniqueIdUser', JSON.stringify(uniqueIdUser));
  };

  const handleModalVisibility = (visible) => {
    if (visible) {
      setModalZIndex(123456);
      setModalVisibility(true);
    } else {
      setModalVisibility(visible);
      setTimeout(() => setModalZIndex(-1), 1000);
    }
  };

  const handleJoinModal = () => {
    window.open(
      `${process.env.REACT_APP_PQ_JOIN}?walletid=${walletId}&uniqueid=${uniqueId}`,
      '_parent'
    );
  };

  const handleCancelModal = () => {
    handleModalVisibility(false);
  };

  const handleConnectWalletClick = () => {
    connectWallet().then((response) => {
      if (response) {
        setWalletToLocalStorage({
          address: JSON.stringify(response?.address),
          starkPublicKey: JSON.stringify(response?.starkPublicKey),
          uniqueIdUser: JSON.stringify(response?.uniqueIdUser),
        });

        setWalletId(response?.address);
        setUniqueId(response?.uniqueIdUser);
        setShown(true);
      }
    });
  };

  const handlePlayAsGuestClick = () => {
    setWalletId('');
    setUniqueId('');
    handleModalVisibility(false);
    setShown(true);
    setShown(true);
  };

  const generateBrowserFingerprint = () => {
    let canvas = document.getElementById('myCanvas');
    let ctx = canvas.getContext('2d');

    ctx.fillStyle = 'rgb(255,0,255)';
    ctx.beginPath();
    ctx.rect(20, 20, 150, 100);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.beginPath();
    ctx.fillStyle = 'rgb(0,255,255)';
    ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();

    const txt = 'abz190#$%^@£éú';
    ctx.textBaseline = 'top';
    ctx.font = '17px "Arial 17"';
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = 'rgb(255,5,5)';
    ctx.rotate(0.03);
    ctx.fillText(txt, 4, 17);
    ctx.fillStyle = 'rgb(155,255,5)';
    ctx.shadowBlur = 8;
    ctx.shadowColor = 'red';
    ctx.fillRect(20, 12, 100, 5);

    // hashing function
    const src = canvas.toDataURL();
    let hash = 0;

    for (let i = 0; i < src.length; i++) {
      let char = src.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    // output this however you want
    return hash;
  };

  const handlePlayerNameOnChange = (e) => {
    setPlayerName(e?.target?.value);
  };

  const setUrlParams = useCallback(() => {
    let searchParser = new URLSearchParams(window.location.search);
    let serverUrl = searchParser.has('server')
      ? searchParser.get('server')
      : '';
    let roomCode = searchParser.has('roomCode')
      ? searchParser.get('roomCode')
      : '';

    if (serverUrl === '') {
      if (continent === 'Europe') {
        serverUrl = 'eu';
      } else if (
        continent === 'North America' ||
        continent === 'South America'
      ) {
        serverUrl = 'na';
      } else if (continent === 'Asia') {
        serverUrl = 'apac';
      } else {
        serverUrl = 'eu';
      }
    }

    let urlParameters = 'server=' + serverUrl;
    if (roomCode !== '') {
      urlParameters += '&roomCode=' + roomCode;
    }
    setParamsURL(urlParameters);
  }, [continent]);

  useEffect(() => {
    const getContinentInfo = async () => {
      let continentChecked = await getContinentByIP();

      if (continentChecked === null) {
        continentChecked = getContinentByTimezone();
      }

      setContinent(continentChecked);
    };

    setBrowserId(generateBrowserFingerprint());

    getContinentInfo().then(() => setUrlParams());
  }, [setUrlParams]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let comingFromPassport = params?.frompassport
      ? !!JSON.parse(params.frompassport)
      : false;

    if (params?.walletid && params?.uniqueid) {
      comingFromPassport = true;

      setWalletToLocalStorage({
        address: params.walletid,
        starkPublicKey: '',
        uniqueIdUser: params.uniqueid,
      });
    }

    const wallet = JSON.parse(localStorage.getItem('address'));
    const uniqueUserId = JSON.parse(localStorage.getItem('uniqueIdUser'));
    const connected = !!wallet && !!uniqueUserId;

    if (document.getElementById('uniqueId') !== null)
      document.getElementById('uniqueId').innerHTML = uniqueUserId;
    
      if (document.getElementById('walletId') !== null)
      document.getElementById('walletId').innerHTML = wallet;

          
    if (document.getElementById('loggedIn') !== null)
      document.getElementById('loggedIn').innerHTML = 'true';
    
    localStorage.setItem('loggedIn', 'true');

    window.document.domain = 'devpq.com';
    setShown(comingFromPassport && connected);
  }, []);

  useEffect(() => {
    localStorage.setItem('game-playerName', playerName);
    
    if (document.getElementById('playerName') !== null)
      document.getElementById('playerName').innerHTML = playerName;
  }, [playerName]);

  useEffect(() => {
    localStorage.setItem('browserId', browserId);

    if (document.getElementById('browserId') !== null)
      document.getElementById('browserId').innerHTML = browserId;
  }, [browserId]);

  return (
    <>
      <Modal
        visibility={modalVisibility}
        onJoin={handleJoinModal}
        onCancel={handleCancelModal}
        zIndex={modalZIndex}
      >
        <>
          <ButtonGroup
            leftButtonText={'JOIN US NOW'}
            rightButtonText={'PLAY PREVIEW'}
            handleLeftButtonClick={handleJoinModal}
            handleRightButtonClick={handlePlayAsGuestClick}
            showSubText={false}
            shown={true}
            inModal={true}
            scaled
          />
        </>
      </Modal>
      <canvas hidden id='myCanvas' width='200' height='40'></canvas>
      <div className={styles.header}>
        <img
          className={styles.headerBorder}
          src={headerBorder}
          alt='header border'
        />
        <img
          className={styles.headerBackground}
          src={headerBackground}
          alt='header background'
        />
        <a
          className={cx(styles.logo, {
            [styles.logoDisappear]: shown && !isMobile(),
          })}
          href='https://planetquest.io'
          target={'_blank'}
          rel={'noreferrer'}
        >
          <img src={logo} alt='planetQuest logo' />
        </a>
      </div>
      <div
        className={cx(styles.App, {
          [styles.iframeShown]: shown,
          [styles.hasBeenADay]: true,
        })}
      >
        <>
          <ButtonGroup
            leftButtonText={'PLAY AS GUEST'}
            rightButtonText={'CONNECT WALLET'}
            handleLeftButtonClick={handlePlayAsGuestClick}
            handleRightButtonClick={handleConnectWalletClick}
            shown={!shown}
            showSubText
            scaled
            inModal={false}
          />

          <div className={styles.playerName}>
            <input
              onChange={handlePlayerNameOnChange}
              value={playerName}
              placeholder='Player Name'
              className={styles.playerNameInput}
              type='text'
            />
          </div>
        </>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '3vw',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            color: 'lightgrey',
            fontFamily: 'Orbitron',
            fontSize: '0.65vw',
          }}
        >
          Work in progress - Does not represent the final look of the game
        </p>
      </div>
      <div id={'playerName'} style={{display: 'hidden'}}></div>
      <div id={'browserId'} style={{display: 'hidden'}}></div>
      <div id={'walletId'} style={{display: 'hidden'}}></div>
      <div id={'uniqueId'} style={{display: 'hidden'}}></div>
      <div id={'loggedIn'} style={{display: 'hidden'}}></div>
      {isMobile() ? (
        <div className={cx(styles.apology, { [styles.shown]: shown })}>
          <div className={styles.title}>
            We Apologise For The Inconvenience, Explorer
          </div>
          <div className={styles.desc}>
            Unfortunately The Genesis Engine Is Not Supported On Mobile Devices
            At The Moment.
          </div>
        </div>
      ) : (
        <iframe
          title={'pq'}
          id={'pq-frame'}
          className={cx(styles.iframe, { [styles.shown]: shown })}
          allow={
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          }
          src={shown ? `${process.env.REACT_APP_DEMO_URL}?${paramsURL}` : ''}
        />
      )}
    </>
  );
}

export default App;
