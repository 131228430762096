/** Dependencies */
import React, { FC } from 'react'
import cx from 'classnames'

/** Components */
import ButtonLeft from '../ButtonLeft/ButtonLeft'
import ButtonRight from '../ButtonRight/ButtonRight'

/** Styles */
import styles from './ButtonGroup.module.scss'

type ButtonGroupProps = {
  leftButtonText: string;
  rightButtonText: string;
  handleLeftButtonClick: React.MouseEventHandler;
  handleRightButtonClick: React.MouseEventHandler;
  showSubText: boolean;
  scaled: boolean;
  inModal: boolean;
  shown: boolean;
}

const ButtonGroup: FC<ButtonGroupProps> = ({
  leftButtonText,
  rightButtonText,
  handleLeftButtonClick,
  handleRightButtonClick,
  showSubText,
  scaled,
  shown,
  inModal
}) => {
  return (
    <div className={cx(styles.buttonGroup, {[styles.inModal]: inModal, [styles.shown]: shown})}>
      <div className={cx(styles.column, styles.columnEnd)}>
        <div
          className={styles.buttonWrapper}
        >
          <ButtonLeft
            scaled={scaled}
            text={leftButtonText}
            handleButtonClick={handleLeftButtonClick}
          />
        </div>
      </div>
      <div className={cx(styles.column, styles.columnStart)}>
        <div
          className={styles.buttonWrapper}
        >
          <ButtonRight
            scaled={scaled}
            text={rightButtonText}
            handleButtonClick={handleRightButtonClick}
          />
          {showSubText && (
            <p className={styles.buttonSubText}>
              *connect wallet to receive
              <br/>
              passport points
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ButtonGroup