export function returnTimeDifference(timeInMS) {
  const now = Date.now();

  const difference = timeInMS - now;

  const differenceInSeconds = Math.floor(difference / 1000);

  const differenceInMinutes = Math.floor(differenceInSeconds / 60);

  const differenceInHours = Math.floor(differenceInMinutes / 60);

  const differenceInDays = Math.floor(differenceInHours / 24);

  const differenceInWeeks = Math.floor(differenceInDays / 7);

  const differenceInMonths = Math.floor(differenceInWeeks / 4);

  const differenceInYears = Math.floor(differenceInMonths / 12);

  return {
    seconds: differenceInSeconds % 60,
    minutes: differenceInMinutes % 60,
    hours: differenceInHours % 24,
    days: differenceInDays % 7,
    weeks: differenceInWeeks % 4,
    months: differenceInMonths % 12,
    years: differenceInYears,
    isValueNegative: timeInMS - now <= 0,
    hasBeenADay: difference <= -86400000,
  };
}

export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getContinentByTimezone() {
  const timezoneInfo = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezoneInfo === '' || !timezoneInfo) {
    return null;
  }
  return timezoneInfo.split('/')[0];
}

export async function getContinentByIP() {
  try {
    let response = await fetch(
      'https://api.ipregistry.co/?key=7boclb3zb3ytuwiw'
    );
    let responseJSON = await response.json();

    // If there was a response and there is a continent name, it uses it. Otherwise it tries with the other one
    if (responseJSON?.location?.continent?.name) {
      return responseJSON.location.continent.name;
    } else {
      response = await fetch('https://ipinfo.io?token=d23b980c965624');
      responseJSON = await response.json();
      return responseJSON.timezone.split('/')[0];
    }
  } catch (error) {
    // If there was an error in the response, it tries the other call. If this one gives error too, then null
    // And we will use the getContinentByTimezone
    try {
      let response = await fetch('https://ipinfo.io?token=d23b980c965624');
      let responseJSON = await response.json();
      return responseJSON.timezone.split('/')[0];
    } catch (error2) {
      return null;
    }
  }
}
