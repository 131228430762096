/** Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/** Images */
import ButtonSVG from '../../assets/svg/button-left-no-border.svg';
import ButtonLeftBorder from '../../assets/svg/button-left-decoration-border.svg';

/** Styles */
import styles from './ButtonLeft.module.scss';

const ButtonLeft = ({ url, text, scaled, handleButtonClick }) => {
  return (
    <a
      href={url}
      target={'_blank'}
      rel={'noreferrer'}
      className={cx(styles.buttonLeft, { [styles.scaled]: scaled })}
    >
      <img className={styles.buttonLeft} src={ButtonSVG} alt='button' onClick={handleButtonClick}/>
      <img
        className={styles.buttonDecorationBorderLeft}
        src={ButtonLeftBorder}
        alt='button bottom left border'
      />
      <span className={styles.buttonLeftText}>{text.split(' ')[0]} {text.split(' ')[1]}</span>
      <span className={styles.secondWordLeftText}>{text.split(' ')[2]}</span>
    </a>
  );
};

ButtonLeft.propTypes = {
  url: PropTypes.string,
  scaled: PropTypes.bool,
  text: PropTypes.string,
};

export default ButtonLeft;
